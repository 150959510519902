import { listen } from 'quicklink';
import { html, body, isIE, isMobile, intersectionObserverSupport, cssMixBlendMode, connection, reducedMotion } from './utils/environment';
import { resize } from './utils/resize';
import { isDefined } from './utils/is';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export function globals() {
  debug('init globals');

  // Classes from environment
  html.classList.add(isIE ? 'is-ie' : 'is-not-ie');
  html.classList.add(isMobile ? 'is-mobile' : 'is-not-mobile');
  html.classList.add(cssMixBlendMode ? 'has-mix-blend-mode' : 'has-not-mix-blend-mode');
  html.classList.add(intersectionObserverSupport ? 'has-intersection-observer' : 'has-not-intersection-observer');
  html.classList.add(reducedMotion ? 'has-reduced-motion' : 'has-not-reduced-motion');

  // debug(window.matchMedia('(prefers-reduced-motion: reduce)').matches);
  // const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
  // if (connection.saveData || ['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
  //   // prevent loading resources and other data saving measures
  // }

  // Inits
  initQuicklink();
  resize.on(responsiveEmbeds);

  // Misc
  copyright();
}

export function onPageLoaded() {
  debug('init onPageLoaded');

  // Inits
  // initFauxLink();
  initSliders();
  initReducedMotion();
  responsiveEmbeds();




  const svgsMask = Array.from( document.querySelectorAll('.svg-mask-wrapper') );
  resize.on(responsiveSvgsMask);
  svgsMask.forEach(el => {
    debug(el);
    
    const mask  = el.querySelector('.svg-mask.-mask');
    const clone = el.querySelector('.svg-mask.-clone');
    const pathsMask  = Array.from( mask.querySelectorAll('path') );
    const circlesMask  = Array.from( mask.querySelectorAll('circle') );
    const pathsClone = Array.from( clone.querySelectorAll('path') );
    const circlesClone = Array.from( clone.querySelectorAll('circle') );

    // const img_w = el.offsetWidth;
    const mask_viewbox = parseInt(mask.dataset.maskWidth);
    const mask_bounds = mask.getBoundingClientRect();;
    const mask_w = mask_bounds.width;

    debug('mask viewbox : ' + mask_viewbox + ' | mask width : ' + mask_w);

    // mask.querySelector('clipPath').style.transform = 'scale(' +  img_w / mask_w + ')';
    mask.querySelector('clipPath').style.transform = 'scale(' +  mask_w / mask_viewbox + ')';
    
    pathsClone.forEach((p, i) => {
      p.addEventListener('mouseenter', () => {
        pathsMask[i].classList.add('-is-hover');
        // pathsMask[i].style.opacity = 0.333;
        // pathsMask[i].style.transform = "scale('1.1')";
      });
    });
    pathsClone.forEach((p, i) => {
      p.addEventListener('mouseleave', () => {
        pathsMask[i].classList.remove('-is-hover');
        // pathsMask[i].style.opacity = 1;
        // pathsMask[i].style.transform = "scale('1')";
      });
    });
    circlesClone.forEach((c, i) => {
      c.addEventListener('mouseenter', () => {
        circlesMask[i].classList.add('-is-hover');
        // circlesMask[i].style.transform = "rotate('180deg')";
        // circlesMask[i].style.opacity = 0.333;
      });
    });
    circlesClone.forEach((c, i) => {
      c.addEventListener('mouseleave', () => {
        circlesMask[i].classList.remove('-is-hover');
        // circlesMask[i].style.opacity = 1;
      });
    });
  });
}


// Set the right width for svgs masks
function responsiveSvgsMask() {
  const svgsMask = Array.from( document.querySelectorAll('.svg-mask-wrapper') );
  svgsMask.forEach(el => {    
    const mask  = el.querySelector('.svg-mask.-mask');

    const mask_viewbox = parseInt(mask.dataset.maskWidth);
    const mask_bounds = mask.getBoundingClientRect();;
    const mask_w = mask_bounds.width;

    mask.querySelector('clipPath').style.transform = 'scale(' +  mask_w / mask_viewbox + ')';
  });
}


// Init Quicklink
// => prefetch in-viewport links during idle time
// https://github.com/GoogleChromeLabs/quicklink
// Only listen to given links
function initQuicklink() {
  if (!intersectionObserverSupport ) {
    return
  }

  let options = isDefined(_quicklinkOptions) ? _quicklinkOptions : {
    el: body,
    // origins: [
    //   site_baseurl,
    //   // site_url.replace(/(^\w+:|^)\/\//, '')
    // ], // default to : [location.hostname]
    ignores: [
      /\/api\/?/,
      /\/wp-admin\/?/,
      /\/wp-login\/?/,
      uri => uri.includes('.zip'),
      uri => uri.includes('#'),
      (uri, elem) => elem.hasAttribute('noprefetch')
    ]
  };
  options.ignores.push(uri => uri.includes('#')); // Don't prefetch url that contains #
  options.ignores.push((uri, elem) => elem.hasAttribute('noprefetch')); // Don't prefetch elem that contains noprefetch attribute
  
  listen(options);
}


// Sliders
function initSliders() {
  let sliders = [...document.querySelectorAll('.c-slider')];
  sliders.forEach( el => initSliderForElem(el) );
}
function initSliderForElem(el) {
  const opt = {
    // Optional parameters
    direction: el.dataset.swiperDirection || 'horizontal', // 'horizontal' | 'vertical'
    loop: el.dataset.swiperLoop === 'false' ? false : true,
    speed: el.dataset.swiperSpeed || 500,
    spaceBetween: el.dataset.swiperSpaceBetween || 0,
    slidesPerView: el.dataset.swiperSlidesPerView || 1,
    grabCursor: true,
    
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
  };
  let hasAutoplay = false;

  // Autoplay
  if ( el.dataset.swiperAutoplay == '1' || el.dataset.swiperAutoplay == 'true' ) {
    hasAutoplay = true;
    opt.autoplay = {
      delay: el.dataset.swiperAutoplayDelay || 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  // Navigation arrows
  if ( el.querySelector('.swiper-button-next') ) {
    opt.navigation = {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    }
  }

  // Pagination
  if ( el.querySelector('.swiper-pagination') ) {
    opt.navigation = {
      el: el.querySelector('.swiper-pagination'),
      type: el.dataset.swiperPaginationType || 'bullets', // 'bullets' | 'fraction' | 'progressbar' | 'custom'
    }
  }

  debug('init swiper', el, opt);
  const swiper = new Swiper(el, opt);

  if (hasAutoplay) {
    swiper.autoplay.pause();

    // Add an observer to pause / run the autoplay
    let observer;

    let options = {
      // root: document,
      rootMargin: '0px',
      threshold: 0.0
    }

    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        // let elem = entry.target;

        if (entry.isIntersecting) {
          // console.log('is enter viewport', elem);
          swiper.autoplay.run();
        } else {
          // console.log('is leaving viewport', elem);
          swiper.autoplay.pause();
        }
      });
    };

    observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  }
}


// // Faux link
// // Click on the elem redirect to the data-href location
// function initFauxLink() {
//   let fauxLink = [...document.querySelectorAll('.o-faux-link')];
//   fauxLink.forEach( link => initFauxLinkForElem(link) );
// }
// function initFauxLinkForElem(el) {
//   // let _this = this;
//   el.addEventListener('click', (e) => {
//     if (e.target.tagName === 'A') {
//       return;
//     }

//     e.preventDefault();

//     let href = el.dataset.href;
//     if (href === '' || href === '#') return;

//     // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
//     // and open the image in a new tab
//     if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
//       return window.open(href, '_blank');
//     }

//     // if ( _this.app.Load ) {
//     //   _this.app.Load.H.redirect(href);
//     //   return;
//     // }
//     if ( _app ) {
//       _app.call('onGoTo', href, 'Load');
//     }

//     return window.location = href;
//   });
// }


// Reduced Motion
function initReducedMotion() {
  // https://polypane.app/blog/creating-websites-with-prefers-reduced-data/
  const videos = [...document.querySelectorAll('video')];

  // default to save data being on
  const saveData = connection.saveData || true;
  const slowConnection = ['slow-2g', '2g', '3g'].includes(connection.effectiveType);

  // test if there is no preference, then also check if saveData is not on
  const canAutoPlayAndPreload = !reducedMotion || !saveData || !slowConnection;

  // debug('saveData', saveData);
  // debug('slowConnection', slowConnection);
  // debug('canAutoPlayAndPreload', canAutoPlayAndPreload);

  videos.forEach(video => {
    video.setAttribute('autoplay', canAutoPlayAndPreload);
    video.setAttribute('preload', canAutoPlayAndPreload);

    // if (reducedMotion) {
      // video.pause();
    // }
  });
}


/**
 * Make embeds responsive so they don't overflow their container.
 * Add max-width & max-height to <iframe> elements, depending on their width & height props.
 *
 * @return {void}
 */
function responsiveEmbeds() {
  var proportion, parentWidth;

  // Loop iframe elements.
  document.querySelectorAll( 'iframe' ).forEach( function( iframe ) {
    // Only continue if the iframe has a width & height defined.
    if ( iframe.width && iframe.height ) {
      // Calculate the proportion/ratio based on the width & height.
      proportion = parseFloat( iframe.width ) / parseFloat( iframe.height );
      // Get the parent element's width.
      parentWidth = parseFloat( window.getComputedStyle( iframe.parentElement, null ).width.replace( 'px', '' ) );
      // Set the max-width & height.
      iframe.style.maxWidth = '100%';
      iframe.style.maxHeight = Math.round( parentWidth / proportion ).toString() + 'px';
    }
  } );
}


// Display some copyright in the console
function copyright () {
  const style = [
    'color: #fff',
    'background: #ffc308',
    'padding: 4px 8px',
    'border-left: 5px solid #282828',
    'border-right: 5px solid #282828'
  ].join(';')
  
  console.log('%cA creation by Boite à Oeufs 🇫🇷', style)
  console.log('%cCopyright © 2022 • Tous droits réservés.', style)
  console.log('%c> Site : https://www.boite-a-oeufs.com', style)
  console.log('%c> Twitter : https://twitter.com/BoiteOeufs', style)
  console.log('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)

  console.log('%c> Design by Julian Camaret', style)
  console.log('%c> Twitter : https://twitter.com/Jus_motion', style)

  console.log('%cDevelopment by Romain Murschel', style)
  console.log('%c> Site : https://romainmurschel.fr', style)
  console.log('%c> Twitter : https://twitter.com/balthorg', style)
}
