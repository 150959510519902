export default `
precision highp float;

uniform vec2 size;
uniform vec2 sizeImage;
uniform sampler2D image;
uniform float reveal;
uniform float hover;

varying float intensity;
// varying float reveal;
varying vec2 vUv;

vec4 coverTexture(vec2 ruv) {
  vec2 s = size;
  vec2 i = sizeImage;
  float rs = s.x / s.y;
  float ri = i.x / i.y;
  vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
  vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
  vec2 uv = ruv * s / new + offset;

  return texture2D(image, uv);
}

float exponentialInOut(float t) {
  return t == 0.0 || t == 1.0
      ? t
      : t < 0.5
      ? +0.5 * pow(2.0, (20.0 * t) - 10.0)
      : -0.5 * pow(2.0, 10.0 - (t * 20.0)) + 1.0;
}

void main() {
  vec2 coords = vUv;

  // Reveal effect
  coords -= 0.5;
  coords *= 0.8 + (intensity * 0.05);
  coords += 0.5;

  // // the distance from this point to the left edge is a float from 0 to 1
  // // float distanceToLeft = distance(vec2(0.0, coords.y), coords);
  // float distanceToLeft = distance(coords, vec2(0.5));

  // // calculate an effect that goes from 0 to 1 depenging on reveal and distanceToLeft
  // float spreadFromLeft = clamp((reveal * (1.0 - distanceToLeft) - 1.0) + reveal * 2.0, 0.0, 1.0);


  // hover effect
  float zoomLevel = .15;

  // float hoverLevel = exponentialInOut(min(1., (distance(vec2(.5), coords) * hover) + hover));
  float hoverLevel = min(1., (distance(vec2(.5), coords) * hover * 5.) + hover);
  coords *= 1. - zoomLevel * hoverLevel;
  coords += zoomLevel / 2. * hoverLevel;

  coords = clamp(coords, 0., 1.);

  vec4 finalColor = coverTexture(coords);

  if (hoverLevel > 0.) {
    hoverLevel = 1.-abs(hoverLevel-.5)*2.;

    // Pixel displace
    coords.y += finalColor.r * hoverLevel * .05;
    finalColor = coverTexture(coords);

    // RGBshift
    // finalColor.r = texture2D(image, coords+(hoverLevel)*0.01).r;
    finalColor.r = coverTexture(coords+(hoverLevel)*0.01).r;
    // finalColor.g = texture2D(image, coords-(hoverLevel)*0.01).g;
    finalColor.g = coverTexture(coords-(hoverLevel)*0.01).g;
  }


  // finalColor = vec4(vec3(finalColor.rgb * spreadFromLeft), 1.);
  finalColor = vec4(vec3(finalColor.rgb), reveal);



  gl_FragColor = finalColor;
}
`;