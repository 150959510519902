import { module } from 'modujs';
import Aladino from 'aladino'; // https://github.com/luruke/aladino
import gsap from 'gsap';
import { raf } from '../utils/raf'; // https://github.com/letmewin22/utilities
import { html, reducedMotion } from '../utils/environment';
import { lerp } from '../utils/math.js';
// import throttle from '../utils/throttle';
import vShaderSource from '../shaders/scroll.v';
import fShaderSource from '../shaders/scroll.f';

export default class extends module {
  constructor(m) {
    super(m);
    this.isInit = false;
  }

  init() {
    if (reducedMotion) return;
    debug('init from Sketch.js');

    this.aladino = null;
    this.material = null;
    this.isCheckingRender = false;
    this.shouldRender = false;
    
    // let oldPos = aladino.y;
    // this.oldPos = scroll.current;
    this.nb = 0;
    this.oldPos = 0;
    this.y = 0;
    
    this.onMouseenter = this.onMouseenter.bind(this);
    this.onMouseleave = this.onMouseleave.bind(this);
    this.onEnter      = this.onEnter.bind(this);
    this.onLeave      = this.onLeave.bind(this);
    this.onLazyloaded = this.onLazyloaded.bind(this);
    this.update       = this.update.bind(this);
  }

  initialize() {
    debug('initialize Sketch.js');

    this.isInit = true;
    this.isCheckingRender = false;
    this.shouldRender = true;
    html.classList.add('has-gl-images');

    // Init Aladino
    this.aladino = new Aladino({
      // canvas: document.querySelector('canvas'),
      canvas: document.getElementById('canvas'),
      density: 20,
    });
    // document.body.appendChild(this.aladino.canvas);
    
    this.material = this.aladino.material({
      vertex: vShaderSource,
      fragment: fShaderSource,
      uniforms: { // Object - Uniforms shared across all the carpets using this material
        speed: 0,
      },
      /*
      {
        enable: false,         // uniform bool enable;
        speed: 0.4,            // uniform float speed;
        scale: [1, 1.2],       // uniform vec2 scale;
        color: [1, 0, 0],      // uniform vec3 color;
        color2: [1, 0, 0, 1],  // uniform vec4 color2;
        tex: aladino.texture() // uniform sampler2D tex;
      }
      */
    });

    // raf.on(this.update);
    this.aladino.resize();

    // this.call('update', 'Scroll');
  }

  add(el) {
    // Has no elem yet ?
    if (!this.isInit) {
      this.initialize();
    }

    // Add this elem
    // this.images = [...document.querySelectorAll('.js-gl-img')];
    // this.images.forEach((el, index) => {
    //   this.aladino.carpet(el, {
    //     material: this.material, // Aladino Material – The material to use for this carpet
    //     wireframe: false, // Boolean – Use gl.LINES
    //     position: [0, 0], // Array[Number, Number] – Offset in px [x, y]
    //     scale: [1, 1], // Array[Number, Number] – Scale multiplier [width, height]
    //     order: 10 + index, // Number - order of depth of the carpet in the scene
    //     uniforms: {
    //       image: this.aladino.texture(el.src),
    //     },
    //   });
    // });

    // Get image if the el isn't an IMG tag
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }

    this.aladino.carpet(img, {
      material: this.material, // Aladino Material – The material to use for this carpet
      // wireframe: false, // Boolean – Use gl.LINES
      // position: [0, 0], // Array[Number, Number] – Offset in px [x, y]
      // scale: [1, 1], // Array[Number, Number] – Scale multiplier [width, height]
      order: 10 - (this.nb / 100), // Number - order of depth of the carpet in the scene
      uniforms: {
        image: this.aladino.texture(img.src),
        // image: this.aladino.texture(img.dataset.lazySrc),
        reveal: 0,
        hover: 0,
      },
    });
    // debug(this.aladino.carpets);

    el.setAttribute('gl-order', 10 - (this.nb / 100))

    el.addEventListener('mouseenter', this.onMouseenter);
    el.addEventListener('mouseleave', this.onMouseleave);

    // el.addEventListener('ScrollReveal:enter', this.onEnter);
    // // Should repeat the reveal effect ?
    // if (el.dataset.scrollRepeat !== undefined) {
    //   el.addEventListener('ScrollReveal:leave', this.onLeave);
    // }

    el.addEventListener('RocketLazyLoaded:Loaded', this.onLazyloaded);

    this.nb++;
  }

  onScroll(y) {
    this.y = parseInt(y);

    if (this.isInit && !this.isCheckingRender) {
      raf.on(this.update);
    }
  }

  onMouseenter(e) {
    let el = e.target;
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }
    debug('Mouseenter in WebGL', el);

    let carpet = this.aladino.carpets.get(img);
    if ( carpet ) {
      gsap.to(carpet.uniforms, {
        // startAt: { reveal: 0 },
        hover: 1,
        ease: 'power2.inOut',
        // ease: 'expo.out',
        duration: 1.2,
      });
    }
  }

  onMouseleave(e) {
    let el = e.target;
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }
    debug('onMouseleave in WebGL', el);

    let carpet = this.aladino.carpets.get(img);
    if ( carpet ) {
      gsap.to(carpet.uniforms, {
        // startAt: { reveal: 0 },
        hover: 0,
        ease: 'power2.out',
        // ease: 'expo.out',
        duration: 1.2,
      });
    }
  }

  onEnter(el) {
    debug('Reveal in WebGL', el);
    // let el = e.target;
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }
    
    let carpet = this.aladino.carpets.get(img);
    if ( carpet ) {
      gsap.to(carpet.uniforms, {
        // startAt: { reveal: 0 },
        reveal: 1,
        ease: 'power3.inOut',
        // ease: 'expo.out',
        duration: 1.2,
      });
    }
  }
  onLeave(el) {
    debug('Leave in WebGL', el);
    // let el = e.target;
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }
    
    let carpet = this.aladino.carpets.get(img);
    if ( carpet ) {
      gsap.to(carpet.uniforms, {
        // startAt: { reveal: 0 },
        reveal: 0,
        ease: 'power3.inOut',
        // ease: 'expo.out',
        duration: 1.2,
      });
    }
  }

  onLazyloaded(e) {
    let el = e.target;
    let img = el;
    if (el.tagName !== 'IMG') {
      img = el.querySelector('img');
    }
    debug('Lazyloaded in WebGL', el);
    
    let carpet = this.aladino.carpets.get(img);
    if ( carpet ) {
      carpet.uniforms.image = this.aladino.texture(img.src);
    }
  }

  update() {
    // requestAnimationFrame(update);

    this.isCheckingRender = true;
    this.shouldRender = html.classList.contains('has-scroll-scrolling');
    // debug('check if is scrolling:', this.shouldRender ? 'is-scrolling' : 'is-not-scrolling');

    if (this.shouldRender) {
      // let y = this.aladino.y;
      let y = this.y;
      let speed = (y - this.oldPos) * 0.1;
      // debug(y, this.oldPos, speed);

      this.material.uniforms.speed = lerp(
        this.material.uniforms.speed,
        speed * 0.2,
        0.06
      );
      // oldPos = aladino.y;
      this.oldPos = y;
    
      this.aladino.resize();
    } else {
      raf.off(this.update);
      this.isCheckingRender = false;
    }
  }

  destroy() {
    debug('destroy from Sketch.js');
    this.isInit = false;
    this.isCheckingRender = false;
    this.shouldRender = false;
    raf.off(this.update);
    if (this.aladino) this.aladino.destroy();
    html.classList.remove('has-gl-images');
  }
}
