import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll'; // https://github.com/locomotivemtl/locomotive-scroll
// import { lazyLoadImage } from '../utils/image';
import { body, reducedMotion } from '../utils/environment';
import { resize } from '../utils/resize';

// Create Events
// https://developer.mozilla.org/fr/docs/Web/Guide/DOM/Events/Creating_and_triggering_events
// const events = {
//   'enter': document.createEvent('Event'),
//   'leave': document.createEvent('Event'),
// };
// events.enter.initEvent('ScrollReveal:enter', true, true);
// events.leave.initEvent('ScrollReveal:leave', true, true);


export default class extends module {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        top: 'scrollToTop',
        content: 'scrollToContent',
        to: 'scrollToTarget'
      }
    }

    this.dynamicScaleElems = [];
  }

  init() {
    if (reducedMotion) return;
    debug('init from Scroll.js', this.el);

    // this.oldY = 0;
    // this.speed = 0;
    this.hasScroll = false;

    // Init SmoothScroll
    this.scroll = new LocomotiveScroll({
      el: this.el,
      smooth: true,
      getSpeed: true,
      lerp: 0.2, // 0.1
      firefoxMultiplier: 50,
      touchMultiplier: 4.5, // 2

      tablet: {
        smooth: true,
        // direction: 'vertical',
        // gestureDirection: 'vertical',
        // breakpoint: 1024,
      },
      smartphone: {
        smooth: true,
      }
    });

    this.scroll.on('call', (func, way, obj, id) => {
      // Using modularJS
      this.call(func[0], { way, obj }, func[1], func[2]);
    });

    this.scroll.on('scroll', (args) => {
      // debug(args.scroll);
      // let y = args.scroll.y;
      // this.speed = (y - this.oldY) * 0.1;
      // this.oldY = y;
      // debug(this.speed);

      if (args.scroll.y > 50) {
        if (!this.hasScroll) {
          this.hasScroll = true;
          body.classList.add('has-scroll');
        }
      } else {
        if (this.hasScroll) {
          this.hasScroll = false;
          body.classList.remove('has-scroll');
          this.call('close', null, 'Navigation');
        }
      }

      this.call('onScroll', args.scroll.y, 'Sketch');
      this.call('onScroll', args.scroll.y, 'Spring');

      // Get all current elements : args.currentElements
      // debug(args);
      for (const [k, o] of Object.entries(args.currentElements)) {
        if (o.el.dataset.reveal !== undefined && o.el.dataset.reveal === 'dynamicScale') {
          // this.setSpring(o.el);
          // debug('progress: ' + o.progress, o);
          this.call('onProgress', o.progress, 'Reveal');
        }
      }
      // if(typeof args.currentElements['hey'] === 'object') {
      //   let progress = args.currentElements['hey'].progress;
      //   debug('progress: ' + progress, args.currentElements['hey']);
      //   // ouput log example: 0.34
      //   // gsap example : myGsapAnimation.progress(progress);
      // }
    });


    this.initAncreLink();

    setTimeout(() => {
      debug('Scroll set scroll limit');
      this.scroll.scroll.setScrollLimit();
    }, 1000);

    this.update = this.update.bind(this);
    resize.on(this.update);
  }

  update() {
    debug('update all element positions');
    // console.warn(this.el.offsetHeight);
    this.scroll.update();
  }

  scrollToTop(e) {
    debug('scroll to top fired !');
    this.scroll.scrollTo('top');
  }
  scrollToContent(e) {
    debug('scroll to content fired !');
    this.scroll.scrollTo(document.querySelector('.wrapper'));
  }
  scrollToTarget(e) {
    debug('scroll to target fired !', e.currentTarget);
    // Get href of this link
    // or get the data-scrollto string
    const target = document.querySelector( e.currentTarget.getAttribute('href') ? e.currentTarget.getAttribute('href') : e.currentTarget.dataset.scrollto );
    if ( target ) {
      debug('scroll to target', target);
      this.scroll.scrollTo(document.querySelector('.wrapper'));
    }
  }
  scrollto(target) {
    if ( target ) {
      debug('scroll to target', target);
      this.scroll.scrollTo(target);
    }
  }

  // onEnter(e) {
  //   let el = e.obj.el;
  //   debug('inview trigger : ' + e.way, el)

  //   if (e.way === 'enter') {
  //     el.dispatchEvent(events.enter);
  //   } else if (e.way === 'exit') {
  //     el.dispatchEvent(events.leave);
  //   }
  // }


  // Ancre Link
  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreLink() {
    const linksAncre = Array.from( document.querySelectorAll('a[href^="#"]:not(#cn-accept-cookie):not(#cn-refuse-cookie):not(.c-back-to-top):not(.tabs__link):not(.u-screen-reader-text):not(.screen-reader-shortcut)') );
    debug('init Ancre link for', linksAncre);
    // Add Event Listener to all links
    linksAncre.forEach( link => this.initAncreClickForElem(link) );
  }
  initAncreClickForElem(el) {
    el.addEventListener('click', (e) => {
      const target = document.querySelector( e.target.getAttribute('href') ? e.target.getAttribute('href') : e.target.dataset.scrollto );
      if ( target ) {
        e.preventDefault();
        debug('scroll to target', target);
        this.scroll.scrollTo(target);
      }
      return false;
    });
  }


  /**
   * Lazy load the related image.
   *
   * @see ../utils/image.js
   *
   * It is recommended to wrap your `<img>` into an element with the
   * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
   * will be applied on both the image and the parent wrapper.
   *
   * ```html
   * <div class="c-lazy o-ratio u-4:3">
   *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
   * </div>
   * ```
   *
   * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
   */
  // lazyLoad(args) {
  //   lazyLoadImage(args.obj.el, null, () => {
  //     //callback
  //   })
  // }

  destroy() {
    debug('destroy from Scroll.js');
    
    // Reset scroll position
    // (for example, can be called when navigating between pages)
    // this.scroll.reset();

    this.scroll.destroy();
  }
}