import { module } from 'modujs';
import { rafTimeout } from '../utils/raf';
import { html } from '../utils/environment';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    debug('init Pageloader', this.el);
    
    this.visible = true;
    this.playing = false;

    if (sessionStorage.getItem('skipIntro')) {
      this.onEnded();
    }
    else {
      this.playing = true;
    }

    // if (this.visible) { // already done in app.js
    //   this.el.classList.add('is-visible');
    // }
    
    if (this.playing) {
      this.play();
    }
  }

  play() {
    if (!this.playing) return;

    rafTimeout(() => {
      this.onEnded();
    }, 650);
  }

  onEnded() {
    sessionStorage.setItem('skipIntro', 'yup');
    this.visible = false;
    this.playing = false;
    html.classList.add('is-ended');
    pageloader.classList.remove('is-visible');
  }
}