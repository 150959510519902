// https://github.com/letmewin22/utilities
import debounce from './debounce';

const TFunc = () => {};

export class Resize {
  constructor() {
    this.cbArray = [];
    this.debounced = TFunc;
    this.init();
  }

  bounds() {
    const methods = ['resizeHandler'];
    methods.forEach(fn => {
      this[fn] = this[fn].bind(this);
    })
  }

  init() {
    this.bounds();
    this.debounced = debounce(this.resizeHandler, 2000, false);
    window.addEventListener('resize', this.debounced);
  }

  resizeHandler() {
    this.cbArray.forEach(cb => cb());
  }

  on(cb = TFunc) {
    cb();
    this.cbArray.push(cb);
  }

  off(cb = TFunc) {
    this.cbArray = this.cbArray.filter(e => e !== cb);
  }

  destroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }
}

const resizeInstance = new Resize();

export const resize = {
  on: (cb = TFunc) => resizeInstance.on(cb),
  off: (cb = TFunc) => resizeInstance.off(cb),
}