import { module } from 'modujs';
import modularLoad from 'modularload';
import { html, body } from '../utils/environment';
import { rafTimeout } from '../utils/raf';
import { onPageLoaded } from '../globals';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    debug('init from Load.js');
    
    this.load = new modularLoad({
      enterDelay: 600, // Minimum delay before the new container enters.
      exitDelay: 50, // Delay before the old container exists after the new enters.
      loadedDelay: 0, // Delay before adding the loaded class. For example, to wait for your JS DOM updates.
      // transitions: {
      //   customTransition: {
      //     enterDelay: 450
      //   }
      // }
    });

    
    // On link click.
    this.load.on('loading', (transition, oldContainer) => {
      debug('--------------------');
      debug('transition start from Load.js', oldContainer);
      html.classList.remove('is-ended');
      html.classList.add('is-leaving');
      rafTimeout(() => {
        this.call('destroy', oldContainer, 'app');
        this.call('destroy', 'Sketch');
      }, 400);
      this.call('close', null, 'Navigation');
      body.classList.remove('has-scroll');
      body.classList.remove('has-reach-footer');
    });
    // On new container enter.
    this.load.on('loaded', (transition, oldContainer, newContainer) => {
      debug('--------------------');
      debug('transition loaded from Load.js', oldContainer, newContainer);
      html.classList.remove('is-leaving');
      body.className = newContainer.dataset.bodyClass;
    });
    // On old container exit.
    this.load.on('ready', (transition, newContainer) => {
      debug('--------------------');
      debug('transition ready from Load.js', newContainer);

      setTimeout(() => {
    
        // Pageloader is out
        // => init the app
        html.classList.add('is-ended');
        this.call('update', newContainer, 'app');
        // this.call('initAncreLink', 'Scroll');
        this.call('addHoverEvents', 'Cursor');
        onPageLoaded();
    
      }, 650);
    });
    // // On all images load.
    // this.load.on('images', () => {
    //   debug('all images is loaded from Load.js');
    // });
  }

  onGoTo(url) {
    if (url) {
      this.load.goTo(url);
    }
  }
}