import { module } from 'modujs';
import { rafTimeout } from '../utils/raf';

export default class extends module {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        header: 'toggleSection'
      }
    }
  }

  init() {
    debug('init Accordion', this.el);

    if (this.getData('open') === 'true') {
      this.$('section')[0].classList.add('-is-open');
    }
  }

  toggleSection(e) {
    debug('toggle fired', e, this.el);

    const target = e.currentTarget;
    const section = this.parent('section', target);
    // const main = this.$('main', section)[0];
    
    if (section.classList.contains('-is-open')) {
      section.classList.remove('-is-open');
    } else {
      if ( this.$('section.-is-open').length ) {
        this.$('section.-is-open')[0].classList.remove('-is-open');

        rafTimeout(() => {
          this.open(section);
        }, 600);

      } else {
        this.open(section);
      }
    }
  }

  open(section) {
    section.classList.add('-is-open');
    this.call('scrollto', section, 'Scroll', 'main');
    
    rafTimeout(() => {
      this.call('update', 'Scroll');
    }, 600);
  }
}