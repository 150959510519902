// Gets the mouse position
export function getMousePos(e) {
  let posx = 0;
  let posy = 0;
  const body = document.body;
  
  if (!e) e = window.event;

  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  }
  else if (e.clientX || e.clientY)    {
    posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
    posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
  }

  return { x : posx, y : posy }
}
