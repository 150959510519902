export default `
attribute vec2 position;
attribute vec2 uv;

uniform mat4 projection;
uniform float time;
uniform float speed;
uniform float reveal;

varying vec2 vUv;
varying float intensity;

#define M_PI 3.1415926535897932384626433832795

float parabola( float x, float k ) {
  return pow( 4.0*x*(1.0-x), k );
}

void main() {
  vUv = uv;

  vec4 pos = vec4(position, 0.0, 1.0);
  vec4 pp = projection * vec4(position, 0.0, 1.0);
  float yy = ((pp / pp.w).y + 1.0) / 2.0;

  pos.z += parabola(clamp(yy, 0.0, 1.0), 0.8) * speed;
  intensity = pos.z;

  float latestStart = .75;
  float activation = (+uv.x - uv.y + 1.) / 2.;
  float startAt = activation * latestStart;
  float vertexProgress = smoothstep(startAt, 1., reveal);

  // pos.y = pos.y + ((sin(uv.x * (M_PI / 1.5)) * -speed) * 0.05) - (.5 * (1. - reveal));
  pos.y = pos.y + ((sin(uv.x * (M_PI / 1.5)) * -speed) * 0.05) - (.25 * (1. - reveal));
  // pos.x *= 1. + (.35 * (1. - vertexProgress));
  pos.x *= 1. + (.25 * (1. - vertexProgress));

  gl_Position = projection * pos;
}
`;