import { module } from 'modujs';
import { body } from '../utils/environment';

export default class extends module {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        toggle: 'onToggle',
      }
    }
  }

  init() {
    debug('init Navigation.js');
    this.isOpen = false;
  }

  onToggle(e) {
    debug('toggle nav fired !', e);
    body.classList.toggle('nav-is-open');
  }

  close() {
    body.classList.remove('nav-is-open');
  }
}