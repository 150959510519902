import { module } from 'modujs';
import { reducedMotion } from '../utils/environment';

export default class extends module {
  constructor(m) {
    super(m);
    this.isInit = false;
  }

  init() {
    if (reducedMotion) return;
    this.isInit = true;
    debug('init GLImage for', this.el, this.el.tagName);
    // _Sketch.init(this.el);
    this.call('add', this.el, 'Sketch');
    // if (this.el.tagName === 'IMG') {
    //   this.call('add', this.el, 'Sketch');
    // } else {
    //   this.call('add', this.el.querySelector('img'), 'Sketch');
    // }

    // this.call('update', 'Scroll');

    // If this element is already in view
    // => launch reveal anim
    if ( this.el.classList.contains('is-inview') ) {
      debug('already in view => reveal', this.el);
      this.call('onEnter', this.el, 'Sketch');
    }
  }

  onEnter(e) {
    // debug(e, this);
    if ( e.obj.el === this.el ) {
      debug('onenter glimage', this.el);

      if (this.isInit) {
        if ( e.way === 'enter' ) {
          this.call('onEnter', this.el, 'Sketch');
        } else if ( e.way === 'exit' ) {
          this.call('onLeave', this.el, 'Sketch');
        }
      }
    }
  }
}