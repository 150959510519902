import { isDebug } from './environment';

export default function(val, val2, val3) {
  if (!isDebug) return;
  if (val3) {
    console.log(val, val2, val3);
  }
  else if (val2) {
    console.log(val, val2);
  } else {
    console.log(val);
  }
}