import { module } from 'modujs';
import { reducedMotion } from '../utils/environment';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    if (reducedMotion) return;
    debug('init from Lightbox.js');

    this.DOM = {
      data: document.querySelectorAll( '*[data-image]' ),
      class: document.querySelectorAll( '.lightbox' ),
      // href: document.querySelectorAll( '*[data-image]' ),
    };
    // this.items = [...this.DOM.data, ...this.DOM.class, ...this.DOM.href];
    this.items = [...this.DOM.data, ...this.DOM.class];
    // debug(this.items);

    if (this.items.length) {
      // Check if the file that contains the library is loaded
      const $vendors = document.getElementById('app-vendors-js');
      if ($vendors) {
        if ($vendors.isLoaded) {
          this.start();
        } else {
          $vendors.addEventListener('load', (event) => {
            this.start();
          });
        }
      } else {
        console.warn('The "app-vendors-js" script not found');
      }
    }
  }

  start() {
    debug('start Lightbox.js');
    this.items.forEach(el => {
      el.classList.add('js-cursor-more');
    });
    Intense( this.items );
  }
}