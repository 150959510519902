import { module } from 'modujs';
import { html } from '../utils/environment';
import { raf } from '../utils/raf';
import { lerp } from '../utils/math.js';
import { transform } from '../utils/transform';
import { reducedMotion } from '../utils/environment';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    if (reducedMotion) return;
    debug('init Spring for', this.el);
    html.classList.add('has-spring-items');

    this.update = this.update.bind(this);

    this.toValue = 150;
    this.spring = {
      target: 0,
      current: 0,
      ease: parseFloat(this.el.dataset.spring) || 0.1,
    };
    this.y = 0;
    this.oldPos = 0;

    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.el.addEventListener('ScrollReveal:enter', this.onEnter);
    this.el.addEventListener('ScrollReveal:leave', this.onLeave);
  }

  onScroll(y) {
    this.y = parseInt(y);
  }

  // onView(e) {
  //   let el = e.obj.el;
  //   if (el === this.el) {
  //     if (e.way === 'enter') {
  //       debug('start spring movement for', this.el);
  //       this.el.style.willChange = 'transform';
  //       raf.on(this.update);
  //     }
  //     else {
  //       this.destroy();
  //     }
  //   }
  // }

  onView(e) {
    // debug(e, this);
    if (e.obj.el === this.el) {
      // debug(this.el);
      if ( e.way === 'enter' ) {
        this.onEnter();
      } else if ( e.way === 'exit' ) {
        this.onLeave();
      }
    }
  }

  // onEnter(e) {
  onEnter() {
    debug('Start Spring movement for', this.el);
    this.el.style.willChange = 'transform';
    raf.on(this.update);
  }

  // onLeave(e) {
  onLeave() {
    debug('Stop Spring movement for', this.el);
    this.el.style.removeProperty('will-change');
    this.el.style.removeProperty('transform');
    raf.off(this.update);
  }

  update() {
    if ( typeof this === 'undefined' ) return;
    let y = this.y;
    let speed = (y - this.oldPos) * 0.1;
    this.oldPos = y;

    this.spring.target = Math.min(speed * 20.0, this.toValue);

    this.spring.current = lerp(
      this.spring.current,
      this.spring.target,
      this.spring.ease
    );
    if (Math.abs(this.spring.current) < 0.01) {
      this.spring.current = 0;
    }

    // debug('update spring: ' + this.spring.current, this.el);
    transform(this.el, `translate3d(0,${this.spring.current}px,0)`);
  }

  destroy() {
    debug('destroy from Spring.js', this.el);
    html.classList.remove('has-spring-items');
    raf.off(this.update);
    this.el.removeEventListener('ScrollReveal:enter', this.onEnter);
    this.el.removeEventListener('ScrollReveal:leave', this.onLeave);
  }
}