import { module } from 'modujs';
import gsap from 'gsap';

import { isMobile, reducedMotion } from '../utils/environment';
import { lerp } from '../utils/math.js';
import { getMousePos } from '../utils/mouse';
import { raf } from '../utils/raf';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    if (isMobile || reducedMotion) return;
    debug('init from Cursor.js');

    this.y = 0;

    this.DOM = {el: this.el};
    this.DOM.circle = this.DOM.el.querySelector('.c-cursor_inner.-circle');
    this.DOM.arrows = {
      right: this.DOM.el.querySelector('.c-cursor_side.-right'),
      left: this.DOM.el.querySelector('.c-cursor_side.-left')
    };
    this.DOM.read = this.DOM.el.querySelector('.c-cursor_read');
    this.DOM.more = this.DOM.el.querySelector('.c-cursor_more');
    this.DOM.circle.style.opacity = 0;

    // debug(this.DOM);

    this.isHidden = false;
    this.scale = 1;
    this.lastScale = 1;
    this.bounds = this.DOM.circle.getBoundingClientRect();

    this.renderedStyles = {
      tx: {previous: 0, current: 0, amt: 0.2},
      ty: {previous: 0, current: 0, amt: 0.2},
    };

    // Track the mouse position
    this.mouse = {x: 0, y: 0};
    
    this.update = this.update.bind(this);

    this.addEvents();
    this.addHoverEvents();
    raf.on(this.update);
  }

  onScroll(y) {
    this.y = parseInt(y);
  }

  addEvents() {
    this.onMouseMoveEv = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
      this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = this.mouse.y - this.bounds.height/2;

      // Animate in the Cursor
      gsap.to(this.DOM.circle, {duration: 0.9, ease: 'Power3.easeOut', opacity: 0.6});

      window.removeEventListener('mousemove', this.onMouseMoveEv);
    };
    this.getMousePos = (ev) => {
      this.mouse = getMousePos(ev);
      this.mouse.y -= this.y;
    }

    window.addEventListener('mousemove', this.onMouseMoveEv);
    window.addEventListener('mousemove', this.getMousePos);

    document.addEventListener('click', () => this.click());
  }

  update() {
    this.renderedStyles['tx'].current = this.mouse.x - this.bounds.width/2;
    this.renderedStyles['ty'].current = this.mouse.y - this.bounds.height/2;

    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
    }

    this.lastScale = lerp(this.lastScale, this.scale, 0.15);

    this.DOM.circle.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.lastScale})`;
  }

  addHoverEvents() {
    // Activate the enter/leave/click methods of the custom cursor when hovering in/out on every <a> (and the close content ctrl)
    [...document.querySelectorAll('a:not(.js-cursor-read):not(.js-cursor-more)'), ...document.querySelectorAll('button:not(.js-cursor-read):not(.js-cursor-more)')].forEach((link) => {
      link.addEventListener('mouseenter', () => this.enter());
      link.addEventListener('mouseleave', () => this.leave());
      link.addEventListener('click', () => this.click());
    });

    // Activate the enter/leave methods of the "Read" cursor when hovering in/out on every <* class="js-cursor-read">
    [...document.querySelectorAll('.js-cursor-read')].forEach((link) => {
      link.addEventListener('mouseenter', () => this.show('read'));
      link.addEventListener('mouseleave', () => this.hide('read'));
    });

    // Activate the enter/leave methods of the "More" (+) cursor when hovering in/out on every <* class="js-cursor-more">
    [...document.querySelectorAll('.js-cursor-more')].forEach((link) => {
      link.addEventListener('mouseenter', () => this.show('more'));
      link.addEventListener('mouseleave', () => this.hide('more'));
    });
  }

  enter() {
    if ( !this.isHidden ) {
      this.DOM.el.classList.add('is-hover');
      this.scale = 1.9;
    }
  }
  leave() {
    if ( !this.isHidden ) {
      this.DOM.el.classList.remove('is-hover');
      this.scale = 1;
    }
  }
  click() {
    this.lastScale = .4;
  }

  // show() {
  //   this.isHidden = false;
  //   this.scale = 1;
  // }
  // hide() {
  //   this.isHidden = true;
  //   this.scale = 0;
  // }
  
  // showArrows() {
  //   gsap.to(Object.values(this.DOM.arrows), {
  //     ease: 'expo.out',
  //     startAt: {x: i => i ? 10 : -10 },
  //     opacity: 1,
  //     x: 0,
  //     duration: 0.8,
  //   });
  // }
  // hideArrows() {
  //   gsap.to(Object.values(this.DOM.arrows), {
  //     ease: 'expo.out',
  //     x: i => i ? 10 : -10,
  //     opacity: 0,
  //     duration: 0.8,
  //   });
  // }

  show(type) {
    this.enter();
    this.DOM.el.classList.add('is-hover-' + type);
    let el = type === 'more' ? this.DOM.more : this.DOM.read;
    gsap.killTweensOf(el);
    gsap.set(el, { opacity: 1 });
    gsap.to(el, {
      ease: 'expo.out',
      startAt: { scale: 0 },
      scale: 1,
      duration: 0.8,
    });
  }
  hide(type) {
    this.leave();
    this.DOM.el.classList.remove('is-hover-' + type);
    let el = type === 'more' ? this.DOM.more : this.DOM.read;
    gsap.killTweensOf(el);
    gsap.to(el, {
      ease: 'expo.out',
      scale: 0,
      duration: 0.8,
      onComplete: () => {
        gsap.set(el, { opacity: 0 });
      }
    });
  }

  // showRead() {
  //   this.enter();
  //   gsap.set(this.DOM.read, { opacity: 1 });
  //   gsap.to(this.DOM.read, {
  //     ease: 'expo.out',
  //     startAt: { scale: 0 },
  //     scale: 1,
  //     duration: 1
  //   });
  // }
  // hideRead() {
  //   this.leave();
  //   gsap.to(this.DOM.read, {
  //     ease: 'expo.out',
  //     scale: 0,
  //     duration: 1,
  //     onComplete: () => {
  //       gsap.set(this.DOM.read, { opacity: 0 });
  //     }
  //   });
  // }
  
  // showMore() {
  //   this.enter();
  //   gsap.set(this.DOM.more, { opacity: 1 });
  //   gsap.to(this.DOM.more, {
  //     ease: 'expo.out',
  //     startAt: { scale: 0 },
  //     scale: 1,
  //     duration: 1
  //   });
  // }
  // hideMore() {
  //   this.leave();
  //   gsap.to(this.DOM.more, {
  //     ease: 'expo.out',
  //     scale: 0,
  //     duration: 1,
  //     onComplete: () => {
  //       gsap.set(this.DOM.more, { opacity: 0 });
  //     }
  //   });
  // }

  destroy() {
    debug('destroy from Cursor.js');
    raf.off(this.update);
    window.removeEventListener('mousemove', this.getMousePos);
  }
}